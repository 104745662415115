import React from "react"
import { Link } from "gatsby"

import styles from "./layout.module.css"
import TwitterIcon from "../images/twitter.svg"
import LinkedInIcon from "../images/linkedin.svg"
import GitHubIcon from "../images/github.svg"
import FeedIcon from "../images/feed.svg"

export default props => {
  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <div className={styles.logo}>
          <Link className={styles.link} to="/">
            MACIEJ WINNICKI
          </Link>
        </div>
        <nav className={styles.menu}>
          <Link className={styles.link} to="/about">
            About me
          </Link>
          <a
            href="https://twitter.com/mthenw"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            <TwitterIcon className={styles.icon} />
          </a>
        </nav>
      </header>
      <main className={styles.content}>{props.children}</main>
      <footer className={styles.footer}>
        <nav className={styles.menu}>
          <a
            href="https://twitter.com/mthenw"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            <TwitterIcon className={styles.icon} />
          </a>
          <a
            href="https://www.linkedin.com/in/maciej-winnicki/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            <LinkedInIcon className={styles.icon} />
          </a>
          <a
            href="https://github.com/mthenw"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            <GitHubIcon className={styles.icon} />
          </a>
          <a href="/rss.xml" className={styles.link}>
            <FeedIcon className={styles.icon} />
          </a>
        </nav>
      </footer>
    </div>
  )
}
